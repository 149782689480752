/**
 * @file: 峰会页面
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Carousel } from 'antd';
import Menu from '../components/Menu';
import Layout from '../components/Layout';
import IconText from '../components/IconText';
import Container from '../components/Container';
import Avatar1 from '../images/avatar-1.png';
import Avatar2 from '../images/avatar-2.png';
import Avatar3 from '../images/avatar-3.png';
import Avatar4 from '../images/avatar-4.png';
import Avatar5 from '../images/avatar-5.png';
import Avatar6 from '../images/avatar-6.png';
import Avatar7 from '../images/avatar-7.png';
import Avatar8 from '../images/avatar-8.png';
import Point1 from '../images/point-1.svg';
import Point2 from '../images/point-2.svg';
import Point3 from '../images/point-3.svg';
import SummitsTitle from '../images/summits-title.png';
import SummitsTitleMobile from '../images/summits-title-mobile.png';
import LogoWhite from '../images/logo-211201-white.svg';
import { useSearch } from '../utils/common';

const MENUS = [
  {
    id: 'summits-banner',
    name: '首页',
    isHash: true,
  },
  {
    id: 'high-lights',
    name: '大会亮点',
    isHash: true,
  },
  {
    id: 'conference-agenda',
    name: '大会议程',
    isHash: true,
  },
  {
    id: 'previous-review',
    name: '往届回顾',
    isHash: true,
  },
  {
    id: 'home',
    name: 'Convertlab官网',
  },
];

// 大会简介
const Introduction = () => {
  return (
    <section id="introduction" className="introduction">
      <Container>
        <CommonTitle>大会简介</CommonTitle>
        <div className="intro-content">
          <p>近两年来，我国经济发展环境面临深刻复杂的变化，加之国际形势的不确定性，各个行业都受到一定程度的冲击，伴随着巨大的市场挑战，以及技术成熟度和创新水平不断的提升，企业数字化转型的周期已经大幅缩短。逆水行舟，不进则退。</p>
          <h3>如何唤醒企业的数智化新能力，铸造以增长为导向的核心驱动力？</h3>
          <p>
            由Convertlab主办的第四届中国营销技术峰会MTSC2021将于上海举办，本届峰会以<span style={{ color: '#E3BC4F' }}>“云领时代 智绘未来”</span>为主题，邀请来自投资方、数字化转型链路上下游合作伙伴、顶级品牌企业共聚一堂，全面剖析技术、增长和数智化转型等多方面趋势与迅速的基础设施建设路径，帮助品牌企业在逆势之下扬帆起航。
          </p>
        </div>
      </Container>
    </section>
  );
};

// 大会亮点
const Highlights = () => {
  return (
    <section id="high-lights" className="high-lights hash-item">
      <Container>
        <CommonTitle>大会亮点</CommonTitle>
        <Row gutter={{ xl: 30, lg: 30, md: 30 }} className="high-lights-content-row">
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <div className="high-light-item">
              <img src={Point1} alt="high-light-1" />
              <h3>全球·视野</h3>
              <p>由Convertlab发起并主办的中国营销技术行业峰会（MTSC）始终致力于为中国企业带来全球营销技术的前沿洞察，将邀请MarTech概念之父，行业顶尖技术服务商，数百名行业创新践行者，构建中国营销技术实战交流平台</p>
            </div>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <div className="high-light-item">
              <img src={Point2} alt="high-light-1" />
              <h3>趋势·引领</h3>
              <p>企业对于数字化转型的需求快速变化的同时，营销技术也在应势而变，整个领域都面临全新的挑战。本届峰会聚焦营销数字化，深刻理解未来发展趋势，全面解读国内外营销数字化的变化与趋势</p>
            </div>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <div className="high-light-item">
              <img src={Point3} alt="high-light-1" />
              <h3>生态·共建</h3>
              <p style={{ letterSpacing: -1 }}>本届中国营销技术行业峰会峰会联合品牌方、技术方、媒体方等合作伙伴，从行业、技术、生态等多维度分享最佳实践和深度洞察，构建一个开放共享的特色生态圈，助力中国整个营销生态的成长</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

function SummitsBanner({ activeHashIndex }) {
  const [scrollTop, setScrollTop] = useState(true);
  const search = useSearch();

  const listenScroll = () => {
    if (document.documentElement.scrollTop > 100 && scrollTop) {
      setScrollTop(false);
    } else if (document.documentElement.scrollTop <= 100 && !scrollTop) {
      setScrollTop(true);
    }
  };

  useEffect(() => {
    listenScroll();
    document.addEventListener('scroll', listenScroll);
    return () => {
      document.removeEventListener('scroll', listenScroll);
    };
  });

  return (
    <>
      <Menu
        customData={MENUS}
        hideApply
        logo={LogoWhite}
        mobilButton={(
          <a
            href={`https://b.convertlab.com/p/16991/${search}`}
            className="apply-btn apply-btn--large orange"
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            查看回放
          </a>
        )}
        className={`summits-menu ${scrollTop ? 'scroll-top' : ''} ${
          typeof activeHashIndex === 'number' ? `active-${activeHashIndex}` : ''
        }`}
      />
      <section id="summits-banner" className="summits-banner hash-item">
        <Container>
          <div className="banner-content">
            <div className="banner-content-inner">
              <h2><b>2021·</b>第四届中国营销技术峰会</h2>
              <img className="summits-title-pc" src={SummitsTitle} alt="summits-title" />
              <img className="summits-title-mobile" src={SummitsTitleMobile} alt="summits-title" />
              <div className="time-place">
                <p>10.14</p>
                <p>中国·上海</p>
              </div>
              <a
                href={`https://b.convertlab.com/p/16991/${search}`}
                className="apply-btn apply-btn--large orange"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                查看回放
              </a>
            </div>
          </div>
          <Introduction />
        </Container>
      </section>
    </>
  );
}

SummitsBanner.propTypes = {
  activeHashIndex: PropTypes.number,
};

function CustomIconText({ title, iconId, content }) {
  return (
    <Col xl={6} sm={12} className="icon-text-col">
      <IconText title={title} content={content} iconId={iconId} iconSize={50} iconPosition="top" />
    </Col>
  );
}

CustomIconText.propTypes = {
  title: PropTypes.string,
  iconId: PropTypes.string,
  content: PropTypes.string,
};

function ArrowTitle({ title }) {
  return (
    <div className="arrow-title">
      <div>{title}</div>
    </div>
  );
}

ArrowTitle.propTypes = {
  title: PropTypes.string,
};

const CommonTitle = ({ children, description }) => {
  return (
    <div className="summits-common-title">
      <h3>{children}</h3>
      {description && <p>{description}</p>}
      <div className="title-bottom-bar" />
    </div>
  );
};

CommonTitle.propTypes = {
  children: PropTypes.string,
  description: PropTypes.string,
};

const GuestItem = ({ name, title, src, pcPreLine }) => {
  return (
    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
      <div className="guest-item">
        <img src={src} alt={name} className={!src ? 'default-bg' : ''} />
        <h3>{name}</h3>
        <p className={pcPreLine ? 'pcPreLine' : ''}>{title}</p>
      </div>
    </Col>
  );
};

GuestItem.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string,
  pcPreLine: PropTypes.bool,
};

// 大会拟邀嘉宾
const Guests = () => {
  return (
    <section id="guests-list" className="guests-list">
      <Container>
        <CommonTitle>大会拟邀嘉宾</CommonTitle>
        <Row>
          <GuestItem src={Avatar1} pcPreLine name="Scott Brinker" title={`美国MarTech大会主席\nMarTech概念之父`} />
          <GuestItem src={Avatar2} pcPreLine name="张小鹏" title={`腾讯智慧零售战略合作部\n数据与营销平台产品总经理`} />
          <GuestItem src={Avatar3} name="管延放" title={`德勤咨询数字化团队\n合伙人`} />
          <GuestItem src={Avatar5} name="王晓丰" title={`Forrester\n首席分析师`} />
          <GuestItem src={Avatar7} pcPreLine name="李 昕" title={`Convertlab首席科学家\n杜克大学教授`} />
          <GuestItem src={Avatar6} name="范秋华" title={`RTBAsia\nCEO及联合创始人`} />
          <GuestItem src={Avatar8} name="罗 旭" title={`纷享销客创始人&CEO`} />
          <GuestItem src={Avatar4} name="高 鹏" title={`Convertlab\n联合创始人兼CEO`} />
        </Row>
      </Container>
    </section>
  );
};

const AgendaRow = ({ timer, content, speakerData = [] }) => {
  return (
    <Row className="agenda-row">
      <Col xl={4} lg={4} md={8} sm={8} xs={8} className="timer-col">
        <div className="timer">{timer}</div>
      </Col>
      <Col xl={10} lg={10} md={16} sm={16} xs={16} className="main-content-col">
        <div className="main-content">{content}</div>
      </Col>
      <Col
        xl={{ span: 10, offset: 0 }}
        lg={{ span: 10, offset: 0 }}
        md={{ span: 16, offset: 8 }}
        sm={{ span: 16, offset: 8 }}
        xs={{ span: 16, offset: 8 }}
        className="speaker-title-wrapperCol"
      >
        {speakerData.map((rowData) => {
          return (
            <Row key={rowData.person} className="speaker-title-row">
              <Col span={8} className={`speaker-col ${rowData.leftWidth ? `leftWidth-${rowData.leftWidth}` : ''} ${rowData.offset ? 'offset' : ''}`}>
                <div className="speaker row-item">
                  <span className={rowData.prefixSpacing ? 'prefix spacing' : 'prefix'}>{rowData.prefix || ''}</span>
                  <span>{rowData.prefix ? '：' : ''}</span>
                  <span className={rowData.spacing ? 'spacing' : ''}>{rowData.person}</span>
                </div>
              </Col>
              <Col span={16} className="title-col">
                <div className={`person-title row-item ${rowData.preLine ? 'preLine' : ''}`}>
                  {rowData.title}
                </div>
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

AgendaRow.propTypes = {
  timer: PropTypes.string,
  content: PropTypes.string,
  speakerData: PropTypes.arrayOf(
    PropTypes.shape({
      person: PropTypes.string,
      title: PropTypes.string,
      leftWidth: PropTypes.number,
      spacing: PropTypes.bool,
      prefixSpacing: PropTypes.bool,
      prefix: PropTypes.string,
    })
  ),
};

// 大会议程
const ConferenceAgenda = () => {
  return (
    <section id="conference-agenda" className="conference-agenda hash-item">
      <Container>
        <CommonTitle>大会拟定议程</CommonTitle>
        <div className="half-day-wrapper">
          <p className="half-day-title">10月14日 上午</p>
          <AgendaRow
            // timer="9:00-9:30"
            timer="--"
            content="嘉宾签到"
          />
          <AgendaRow
            // timer="9:30-10:00"
            timer="--"
            content="数字时代进化之路"
            speakerData={[
              { person: '高鹏', spacing: true, title: 'Convertlab联合创始人兼CEO', short: true },
            ]}
          />
          <AgendaRow
            // timer="9:30-10:00"
            timer="--"
            content="从大数据到大运营：营销和客户体验新基础"
            speakerData={[
              { person: 'Scott Brinker', title: 'MarTech概念之父 ', short: true, leftWidth: 80 },
            ]}
          />
          <AgendaRow
            // timer="10:00-10:30"
            timer="--"
            content="公私域联动的智能营销云体系"
            speakerData={[
              { person: '张小鹏', title: '腾讯智慧零售战略合作部数据与营销平台产品总经理', short: true }
            ]}
          />
          <AgendaRow
            // timer="10:30-10:45"
            timer="--"
            content="营销技术之未来展望与中国现状"
            speakerData={[
              { person: '王晓丰', title: 'Forrester首席分析师', short: true },
            ]}
          />
          <AgendaRow
            // timer="10:45-11:15"
            timer="--"
            content="增长爆炸的前夜：AI Marketing已来"
            speakerData={[
              { person: '李昕', spacing: true, title: 'Convertlab首席科学家 杜克大学教授', short: true },
              { person: '李征', spacing: true, title: 'Convertlab联合创始人兼CTO', short: true },
            ]}
          />
          <AgendaRow
            // timer="11:15-11:35"
            timer="--"
            content="微软云和人工智能技术，智汇营销数字化"
            speakerData={[
              { person: '崔宏禹', title: '微软(中国)有限公司全渠道事业部创新技术总经理', short: true },
            ]}
          />
          <AgendaRow
            // timer="12:00-14:00"
            timer="--"
            content="VIP午宴"
          />
        </div>
        <div className="half-day-wrapper">
          <p className="half-day-title">10月14日 下午</p>
          <AgendaRow
            // timer="11:35-12:00"
            timer="--"
            content="能力变革：营销云成功的充分条件"
            speakerData={[
              { person: '管延放', title: '德勤咨询数字化团队合伙人', short: true },
            ]}
          />
          <AgendaRow
            // timer="15:30-16:00"
            timer="--"
            content="后疫情时代，拥抱零售新浪潮引领消费升级"
            speakerData={[
              { person: '李兴波', title: '萬家数科产品中心总监' },
            ]}
          />
          <AgendaRow
            // timer="15:00-15:30"
            timer="--"
            content="隐私保护和数字广告生态发展趋势"
            speakerData={[
              { person: '范秋华', title: 'RTBAsia CEO及联合创始人' },
            ]}
          />
          <AgendaRow
            // timer="16:00-16:30"
            timer="--"
            content="营销人吐槽大会：大互联时代，我想和你谈谈"
            speakerData={[
              { prefix: '主持人', person: '王琤', spacing: true, title: 'Convertlab联合创始人兼COO', leftWidth: 75 },
              { prefix: '嘉宾', prefixSpacing: true, person: '罗旭', spacing: true, title: '纷享销客创始人兼CEO' },
              { person: '聂焱', spacing: true, title: '苏泊尔消费者运营总监', offset: true },
              // { person: 'Laura Xu', title: '米其林CDO', offset: true, leftWidth: 52 },
              { person: 'Donna Li', title: '华润三九用户运营及数据系统总监', offset: true, leftWidth: 52 },
              { person: '顾昕', spacing: true, title: '如新企业架构总监', offset: true },
              { person: '赵永', spacing: true, title: '爱点击集团执行副总裁 智慧零售事业部总裁', offset: true },
              // { person: '--', title: '有赞', offset: true },
              // { person: '--', title: '微盟', offset: true },
            ]}
          />
          <AgendaRow
            // timer="17:00-17:30"
            timer="--"
            content="《个保法》推行在即，营销领域的数据安全该如何随动而动？"
            speakerData={[
              { prefix: '主持人', person: '宁宁', spacing: true, title: 'Convertlab 广告技术和生态VP', leftWidth: 75 },
              { prefix: '嘉宾', person: '王胜利', prefixSpacing: true, title: '华控清交高级副总裁', leftWidth: 75 },
              { person: '栾燕', spacing: true, preLine: true, title: `国家工业信息安全发展研究中心\n系统所测试部主任、资深研究员`, offset: true },
              { person: '潘永建', title: '通力律师事务所合伙人', offset: true },
              { person: 'Darryl Su', title: '链睿Liveramp中国区副总裁', offset: true, leftWidth: 56 },
              // { person: '...（持续邀请中）', title: '', leftWidth: 140 },
            ]}
          />
        </div>
      </Container>
    </section>
  );
};

const PreviousItem = ({ src, alt, title, date, href }) => {
  const search = useSearch();
  return (
    <a href={href + search} className="previous-item-link">
      <Row className="previous-item">
        <Col lg={8} className="summit-image">
          <img width="100%" height="100%" src={src} alt={alt} />
        </Col>
        <Col lg={16} className="summit-content">
          <div className="summit-title">{title}</div>
          <div className="summit-date">
            <span>{date}</span>
            <span className="read-tip">立即阅读</span>
          </div>
        </Col>
      </Row>
    </a>
  );
};

PreviousItem.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  href: PropTypes.string,
};

const PrevListCol = ({ index }) => {
  return (
    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
      <img
        className="prev-list-image"
        src={`//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/blogs/prev-list-${index}.jpg`}
        alt={`prev-list-${index}`}
      />
    </Col>
  );
};

PrevListCol.propTypes = {
  index: PropTypes.string,
};

// 往届回顾
const PreviousReview = () => {
  const [current, setCurrent] = useState(1);

  const updateCurrent = (curr) => {
    setCurrent(curr + 1);
  };
  return (
    <section id="previous-review" className="previous-review hash-item">
      <Container>
        <CommonTitle>往届回顾</CommonTitle>
        <Row gutter={{ xl: 30, lg: 30, md: 0, sm: 0 }}>
          <Col xl={12} lg={12} md={24} sm={24}>
            <PreviousItem
              src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/blogs/prev-2019.jpg"
              alt="summit-1"
              title="MarTech进入再次黄金时代，第三届中国营销技术峰会在沪顺利召开"
              date="2019年09月26日"
              href="/blog/20190926-2132/"
            />
            <PreviousItem
              src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/blogs/prev-2018.jpg"
              alt="summit-2"
              title="MTSC 2018 中国营销技术峰会：看得见的营销技术"
              date="2018年08月08日"
              href="/blog/20180808-1439/"
            />
            <PreviousItem
              src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/blogs/prev-2017.jpg"
              alt="summit-3"
              title="中国营销技术峰会：预见MarTech以“中国速度”迅猛发展"
              date="2017年09月13日"
              href="/blog/20170913-2045/"
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24}>
            <Carousel
              autoplay
              arrows
              afterChange={updateCurrent}
              prevArrow={
                <div>
                  <div className="left-button carousel-custom-button">
                    <span className="iconfont font-26 iconless-than" />
                  </div>
                </div>
              }
              nextArrow={
                <div>
                  <div className="right-button carousel-custom-button">
                    <span className="iconfont font-26 icongreater-than" />
                  </div>
                </div>
              }
            >
              <div className="prev-carousel-item">
                <img
                  width="100%"
                  height="100%"
                  src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/blogs/prev-carousel-2019.jpg"
                  alt="prev-carousel-2019"
                />
              </div>
              <div className="prev-carousel-item">
                <img
                  width="100%"
                  height="100%"
                  src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/blogs/prev-carousel-2018.jpg"
                  alt="prev-carousel-2018"
                />
              </div>
              <div className="prev-carousel-item">
                <img
                  width="100%"
                  height="100%"
                  src="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/blogs/prev-carousel-2017.jpg"
                  alt="prev-carousel-2017"
                />
              </div>
            </Carousel>
            <div className="custom-pagi">{current} / 3</div>
          </Col>
        </Row>
        <Row gutter={{ xl: 30, md: 30, sm: 30, xs: 15 }}>
          <PrevListCol index="1" />
          <PrevListCol index="2" />
          <PrevListCol index="3" />
          <PrevListCol index="4" />
          <PrevListCol index="5" />
          <PrevListCol index="6" />
          <PrevListCol index="7" />
          <PrevListCol index="8" />
        </Row>
      </Container>
    </section>
  );
};

const DemoLogo = ({ index }) => {
  const positionX = 0;
  const positionY = (index - 1) * 51 * 2; // 图片为 2x
  const xStr = `-${positionX}px`;
  const yStr = `-${positionY}px`;
  return (
    <Col xl={4} lg={4} md={6}>
      <div className="logo">
        <div className="logo-image" style={{ backgroundPosition: `${xStr} ${yStr}` }} />
      </div>
    </Col>
  );
};

DemoLogo.propTypes = {
  index: PropTypes.number,
};

// 支持机构
const SupportOrganizations = () => {
  return (
    <section id="support-organizations" className="support-organizations">
      <Container>
        <CommonTitle>支持机构</CommonTitle>
        <div className="first-logo-row">
          <Row gutter={{ xl: 30, lg: 30, md: 30, sm: 15, xs: 15 }}>
            <Col span={12}>
              <div className="org-group-wrapper">
                <p className="group-title">主办方</p>
                <Row>
                  <DemoLogo index={1} />
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div className="org-group-wrapper">
                <p className="group-title">赞助单位</p>
                <Row>
                  <DemoLogo index={16} />
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="org-group-wrapper">
          <p className="group-title">合作媒体</p>
          <Row gutter={{ xl: 30, lg: 30, md: 30, sm: 15, xs: 15 }}>
            <DemoLogo index={2} />
            <DemoLogo index={3} />
            <DemoLogo index={4} />
            {/*<DemoLogo index={5} />*/}
            <DemoLogo index={6} />
          </Row>
        </div>
        <div className="org-group-wrapper">
          <p className="group-title">合作机构</p>
          <Row gutter={{ xl: 30, lg: 30, md: 30, sm: 15, xs: 15 }}>
            <DemoLogo index={7} />
            <DemoLogo index={8} />
            <DemoLogo index={9} />
            <DemoLogo index={10} />
            {/*<DemoLogo index={11} />*/}
            <DemoLogo index={12} />
            <DemoLogo index={13} />
            <DemoLogo index={14} />
          </Row>
        </div>
        <div className="org-group-wrapper">
          <p className="group-title">直播平台合作</p>
          <Row gutter={{ xl: 30, lg: 30, md: 30, sm: 15, xs: 15 }}>
            <DemoLogo index={15} />
          </Row>
        </div>
      </Container>
    </section>
  );
};

let divHeights = [];
let divClientHeights = [];
// const classList = ['summits-banner', 'high-lights', 'conference-agenda', 'previous-review'];
let prevScroll = 0;
export default () => {
  const [currentMenuIndex, setCurrentMenuIndex] = useState(0);

  const listenScroll = () => {
    const currentScroll = document.documentElement.scrollTop;

    if (currentScroll - prevScroll > 10) {
      // 防抖 + 向下滚动
      divHeights.forEach((div, index) => {
        if (currentScroll > div - 50 && index > currentMenuIndex) {
          setCurrentMenuIndex(index);
        }
      });
    } else if (prevScroll - currentScroll > 10) {
      // 防抖 + 向上滚动
      for (let i = 2; i >= 0; i--) {
        const item = divHeights[i];
        if (
          currentScroll < item + divClientHeights[i] + 50 &&
          currentScroll > divHeights[i] - 50 &&
          currentMenuIndex > i
        ) {
          setCurrentMenuIndex(i);
          break;
        }
      }
    }
    setTimeout(() => {
      prevScroll = currentScroll - 1;
    }, 0);
  };

  useEffect(() => {
    document.addEventListener('scroll', listenScroll);
    return () => {
      document.removeEventListener('scroll', listenScroll);
    };
  });

  useEffect(() => {
    divClientHeights = [1200, 538, 1829, 987];
    divHeights = [0, 1200, 1738, 3567];
  }, []);
  return (
    <Layout current="summits" subCurrent="summits" hideMenu hideApply hideFixedBtns>
      <div className="summits-bg">
        <SummitsBanner activeHashIndex={currentMenuIndex} />
        <Highlights />
        <Guests />
        <ConferenceAgenda />
        <PreviousReview />
        <SupportOrganizations />
      </div>
    </Layout>
  );
};
